export const GAME_TITLE = '🇷🇴 Wordle-RO'

export const WIN_MESSAGES = ['Bună treabă!', 'Super!', 'Bravo!', 'Excelent!']
export const GAME_COPIED_MESSAGE = 'Am copiat rezultatul în clipboard'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Prea puține litere'
export const WORD_NOT_FOUND_MESSAGE = 'Nu am găsit cuvântul'
export const HARD_MODE_ALERT_MESSAGE =
  'Modul Greu poate fi activat doar la început!'
export const HARD_MODE_DESCRIPTION =
  'Orice indicii obținute trebuie folosite în încercările ulterioare'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'Pentru a îmbunătăți diferențierea culorilor'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Cuvântul era ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Trebuie să folosești ${guess} în poziția ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Încercarea trebuie să conțină ${letter}`
export const ENTER_TEXT = 'Ghici!'
export const DELETE_TEXT = 'Șterge'
export const STATISTICS_TITLE = 'Statistici'
export const GUESS_DISTRIBUTION_TEXT = 'Distribuția ghicirilor'
export const NEW_WORD_TEXT = 'Cuvânt nou în '
export const SHARE_TEXT = 'Distribuie'
export const TOTAL_TRIES_TEXT = 'Încercări'
export const SUCCESS_RATE_TEXT = 'Rata de succes'
export const CURRENT_STREAK_TEXT = 'Câștiguri neîntrerupte'
export const BEST_STREAK_TEXT = 'Cel mai bun șir de câștiguri'