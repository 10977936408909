export const WORDS = [
'rimel',
'dubiu',
'bruna',
'dejun',
'bilet',
'sudor',
'zoaie',
'turma',
'logic',
'iasca',
'funie',
'barza',
'faina',
'tahan',
'menaj',
'noima',
'pusca',
'trusa',
'rabie',
'motto',
'copac',
'anuar',
'dolar',
'teama',
'lagar',
'antet',
'cobza',
'futil',
'teanc',
'metan',
'coaja',
'tanar',
'roaba',
'extaz',
'sobru',
'manie',
'meteo',
'voios',
'sofer',
'spada',
'moale',
'jalba',
'ulcea',
'tenie',
'bucle',
'rampa',
'idiot',
'ireal',
'botez',
'liric',
'numar',
'crunt',
'voiaj',
'fiara',
'penel',
'pusta',
'ninge',
'balon',
'coada',
'conac',
'ceaun',
'folie',
'nalba',
'miros',
'sapun',
'ulcer',
'ficat',
'colea',
'opium',
'tucan',
'mesaj',
'albie',
'batic',
'alias',
'scama',
'parai',
'bacil',
'prova',
'ceara',
'turci',
'maine',
'sepie',
'copan',
'hamal',
'fluid',
'cusur',
'liant',
'sesam',
'bonus',
'urgie',
'talon',
'cheag',
'lipie',
'fatal',
'otava',
'dativ',
'norma',
'zemos',
'copil',
'spuma',
'oblon',
'prost',
'spate',
'sadic',
'mereu',
'mumie',
'noian',
'vanat',
'lucru',
'satul',
'lujer',
'rodeo',
'ranza',
'mersi',
'turba',
'fraga',
'barca',
'patru',
'minim',
'inima',
'cafea',
'alama',
'lexic',
'Parca',
'garou',
'vapor',
'pifan',
'scurt',
'aluat',
'recif',
'motiv',
'timid',
'bideu',
'bogat',
'faima',
'virus',
'apasa',
'fonta',
'soclu',
'acolo',
'infam',
'somer',
'magic',
'banie',
'cumul',
'geana',
'valva',
'drama',
'frige',
'juriu',
'start',
'negru',
'parte',
'saleu',
'soroc',
'ponei',
'bruta',
'balot',
'ratat',
'crama',
'racem',
'godac',
'hrean',
'canin',
'pitic',
'linge',
'susan',
'malin',
'urias',
'ragaz',
'retro',
'jupan',
'dalie',
'cupla',
'puric',
'adica',
'pauni',
'tunde',
'vaiet',
'aluna',
'mosie',
'cirip',
'recul',
'berar',
'aport',
'liman',
'merge',
'letal',
'clovn',
'masiv',
'terci',
'liber',
'fault',
'tabel',
'aprig',
'coiot',
'imita',
'penaj',
'zbura',
'furie',
'harta',
'mosor',
'puseu',
'creol',
'butoi',
'felie',
'cadru',
'petec',
'dunga',
'limax',
'zarva',
'nisip',
'moloz',
'subit',
'sonic',
'obraz',
'arici',
'stima',
'etans',
'iulie',
'vataf',
'fidel',
'neaos',
'scrum',
'baros',
'avans',
'mojar',
'debil',
'retus',
'surub',
'amuza',
'paria',
'exces',
'releu',
'litru',
'aspru',
'super',
'scula',
'nobil',
'podis',
'pensa',
'meniu',
'natur',
'dosar',
'cusut',
'livra',
'baban',
'cezar',
'vacar',
'coral',
'cinic',
'strop',
'garaj',
'carie',
'dieta',
'plaga',
'culme',
'valid',
'vitel',
'fenta',
'umbra',
'claie',
'forum',
'sufix',
'fraza',
'aseza',
'boema',
'vamal',
'ciupi',
'matur',
'cocor',
'adanc',
'arata',
'donor',
'vechi',
'posta',
'conic',
'plasa',
'boata',
'epila',
'sfant',
'fidea',
'odios',
'sirop',
'ferma',
'forma',
'piept',
'palat',
'stare',
'guvid',
'cosar',
'decan',
'acela',
'ezita',
'amara',
'basca',
'pudel',
'goaza',
'refuz',
'uzual',
'robot',
'ficus',
'veste',
'raliu',
'devia',
'lacom',
'safir',
'alpin',
'cotoi',
'sansa',
'ruina',
'buric',
'boxer',
'staul',
'freza',
'moaca',
'molid',
'sadea',
'decor',
'falie',
'lasou',
'axial',
'racan',
'fiord',
'amurg',
'sidef',
'clima',
'crede',
'zgura',
'somon',
'lunga',
'vorba',
'cheta',
'maxim',
'urban',
'ucide',
'butic',
'dogma',
'colet',
'altoi',
'stalp',
'deget',
'duios',
'capra',
'zalog',
'bafta',
'apare',
'madam',
'volum',
'panta',
'raios',
'minus',
'brici',
'anima',
'grajd',
'legal',
'clasa',
'sepia',
'renal',
'cifru',
'conex',
'verde',
'pasat',
'pranz',
'color',
'nodul',
'sarma',
'piata',
'bigam',
'fauna',
'curge',
'atlet',
'peron',
'mucos',
'asana',
'mulge',
'bovin',
'masat',
'amorf',
'tupeu',
'pozna',
'codex',
'rafie',
'egida',
'aripa',
'radio',
'araci',
'calut',
'furis',
'motor',
'pisoi',
'craca',
'tutor',
'benga',
'viola',
'ditai',
'lucla',
'zmeur',
'galon',
'anula',
'exact',
'curte',
'vizor',
'cutie',
'ungur',
'liana',
'lenes',
'totul',
'varza',
'medic',
'carte',
'uituc',
'auriu',
'greva',
'sosea',
'polen',
'halat',
'ocnas',
'ratez',
'pudic',
'braga',
'tronc',
'domol',
'clean',
'cumva',
'cruce',
'intre',
'coama',
'rateu',
'audia',
'poeta',
'deseu',
'noroi',
'nunta',
'gheba',
'rigid',
'difuz',
'cipic',
'odata',
'gelat',
'croat',
'aburi',
'final',
'perie',
'marte',
'freon',
'bucal',
'salin',
'molie',
'calup',
'misca',
'tiraj',
'cazac',
'tifon',
'geniu',
'sursa',
'bezna',
'piesa',
'lesne',
'chiui',
'pisic',
'amper',
'cinel',
'cauta',
'basma',
'minte',
'caina',
'sigur',
'nufar',
'bronz',
'teren',
'impas',
'fizic',
'islaz',
'banet',
'strut',
'raion',
'enunt',
'arcas',
'babac',
'dozaj',
'criza',
'dioda',
'milos',
'cheie',
'umple',
'vagon',
'firiz',
'viran',
'prima',
'graur',
'curea',
'rebut',
'tarta',
'rigla',
'amplu',
'arcan',
'spata',
'rapid',
'targa',
'vatra',
'sinus',
'stoic',
'rodie',
'capac',
'nasos',
'gutui',
'recte',
'tanin',
'treaz',
'sunca',
'sport',
'ageri',
'tarie',
'roman',
'clips',
'odaie',
'baraj',
'podea',
'pocal',
'comod',
'activ',
'venit',
'sauna',
'banut',
'gripa',
'matca',
'extra',
'obste',
'mezat',
'iaurt',
'stofa',
'tinda',
'bufni',
'popas',
'beton',
'forte',
'blugi',
'priza',
'cazna',
'calma',
'tanti',
'dogar',
'diblu',
'fetal',
'colon',
'umfla',
'vecin',
'pufos',
'etapa',
'catel',
'olita',
'plebe',
'desen',
'nimic',
'sonar',
'canto',
'vajai',
'efect',
'umbla',
'iresa',
'xerox',
'optar',
'burta',
'renta',
'cadou',
'balai',
'gruba',
'jegos',
'rasol',
'ceapa',
'matol',
'goana',
'chiar',
'zidar',
'urcus',
'icter',
'afara',
'pilos',
'total',
'tesut',
'miime',
'socru',
'copoi',
'cavou',
'idila',
'raset',
'foame',
'argat',
'spasm',
'grasa',
'habar',
'joben',
'tapet',
'demon',
'vinil',
'zuluf',
'mamos',
'masea',
'arama',
'bidon',
'scaun',
'poama',
'numai',
'cobai',
'jeton',
'botos',
'golan',
'altar',
'betie',
'netot',
'paste',
'comun',
'neamt',
'halda',
'marti',
'cubic',
'gaura',
'rabin',
'bizar',
'codat',
'viers',
'dublu',
'blama',
'clica',
'canoe',
'arest',
'vecie',
'popor',
'pirat',
'podet',
'buluc',
'glont',
'catod',
'cucui',
'iesle',
'ocult',
'tigru',
'mamut',
'alert',
'decat',
'divin',
'harem',
'barba',
'gulag',
'ratie',
'nasol',
'caras',
'tatar',
'loial',
'anale',
'harpa',
'reper',
'cruci',
'vidra',
'cires',
'zebra',
'borna',
'urmas',
'casca',
'rebus',
'lumen',
'patos',
'modem',
'perla',
'gunoi',
'mezel',
'zeghe',
'larva',
'lista',
'solie',
'anost',
'urlet',
'graba',
'vuiet',
'efort',
'puroi',
'clipi',
'chica',
'potir',
'camin',
'nimfa',
'maner',
'femur',
'pilon',
'aspic',
'karma',
'altul',
'rapai',
'elice',
'sezon',
'aoleu',
'macac',
'pelin',
'vaduv',
'lance',
'caine',
'iobag',
'plici',
'etnic',
'coace',
'cinci',
'loaba',
'apret',
'hotel',
'trage',
'uzina',
'taram',
'pilaf',
'ultra',
'laser',
'libra',
'media',
'rival',
'trist',
'cecen',
'boala',
'grefa',
'curat',
'ocean',
'cupaj',
'galos',
'blond',
'vizir',
'nomad',
'moara',
'loaza',
'steag',
'miere',
'dinar',
'locas',
'salva',
'volan',
'taman',
'naduf',
'barfi',
'resou',
'claca',
'chist',
'fileu',
'mediu',
'tinta',
'ludic',
'birou',
'rulou',
'banjo',
'scade',
'solda',
'dopaj',
'scena',
'arcui',
'linie',
'spion',
'creme',
'varsa',
'fuior',
'cotor',
'derby',
'rebel',
'hamei',
'bomba',
'venin',
'clama',
'fular',
'turla',
'falca',
'grija',
'calic',
'cadet',
'tolba',
'slana',
'gruie',
'crapa',
'ghici',
'chior',
'banca',
'vulpe',
'naiba',
'radar',
'popou',
'audio',
'morav',
'crosa',
'lesin',
'panou',
'roata',
'lalea',
'trupa',
'apuca',
'focos',
'ansoa',
'obtuz',
'firma',
'lizol',
'echer',
'ecler',
'tabac',
'moral',
'colos',
'mucus',
'naval',
'prunc',
'aleza',
'milog',
'blana',
'fluor',
'tigla',
'franc',
'foraj',
'trust',
'mitic',
'estic',
'remis',
'alice',
'amica',
'mason',
'verso',
'sonet',
'bujor',
'amant',
'tapir',
'doina',
'danez',
'sfada',
'perja',
'banal',
'atela',
'feuda',
'teapa',
'masca',
'mizer',
'geros',
'place',
'ghiul',
'fudul',
'sejur',
'trufa',
'valma',
'favor',
'lauda',
'saxon',
'pompa',
'sfert',
'stana',
'jilav',
'deviz',
'viril',
'jerba',
'email',
'bolta',
'punga',
'tabla',
'titlu',
'popic',
'plaja',
'mixer',
'balta',
'parol',
'fisic',
'acces',
'roura',
'canar',
'trosc',
'bigot',
'sanie',
'duium',
'ostas',
'ultim',
'aoleo',
'brusc',
'menta',
'pofta',
'aorta',
'cneaz',
'soric',
'sarut',
'zalud',
'baron',
'augur',
'balet',
'minge',
'carut',
'clisa',
'cotet',
'panza',
'iunie',
'umbri',
'curba',
'roade',
'haita',
'cuget',
'video',
'rahat',
'maces',
'solid',
'inger',
'colic',
'dulap',
'terna',
'presa',
'fason',
'bravo',
'mixaj',
'plisc',
'capsa',
'banos',
'radiu',
'cauza',
'regie',
'holda',
'ciung',
'codru',
'frate',
'sfios',
'munte',
'cojoc',
'anume',
'aroga',
'malai',
'tipic',
'impur',
'foaie',
'retea',
'huila',
'izvor',
'puhoi',
'baiat',
'putea',
'dihai',
'talie',
'carja',
'bland',
'trapa',
'temei',
'buton',
'aliaj',
'viraj',
'chiul',
'barat',
'maini',
'zeama',
'palid',
'vesta',
'lebar',
'chema',
'dinam',
'huzur',
'sarac',
'motan',
'festa',
'cerne',
'catre',
'zadar',
'bubui',
'ocara',
'anual',
'oaste',
'tacam',
'agrar',
'untar',
'toaca',
'fusta',
'major',
'ursuz',
'ursar',
'butuc',
'genom',
'vocal',
'amnar',
'raget',
'notar',
'bordo',
'furca',
'ortac',
'duble',
'doaga',
'natie',
'gazeu',
'deloc',
'prada',
'metal',
'potop',
'piure',
'ciuda',
'afund',
'fante',
'voleu',
'calus',
'arena',
'ceafa',
'breaz',
'vital',
'gaini',
'clona',
'fugar',
'macru',
'barda',
'zodie',
'clipa',
'torid',
'avere',
'lacat',
'binom',
'muget',
'barou',
'dalta',
'ninja',
'fruct',
'etica',
'vreme',
'album',
'marfa',
'index',
'cocon',
'moroi',
'tasta',
'paine',
'tonic',
'cazan',
'siloz',
'dubla',
'varui',
'incet',
'nazal',
'preot',
'disco',
'zorit',
'pahar',
'ospat',
'belea',
'baroc',
'anunt',
'anexa',
'cacao',
'solar',
'flasc',
'atlas',
'astru',
'dobos',
'secol',
'taler',
'vesel',
'tarot',
'puiet',
'atent',
'zbate',
'bocna',
'istet',
'serie',
'pubis',
'oiste',
'tacit',
'vicar',
'limba',
'acerb',
'apnee',
'fleac',
'sufla',
'finit',
'alipi',
'caiet',
'rubin',
'vazut',
'noroc',
'limfa',
'aspri',
'codas',
'leton',
'fiere',
'mafie',
'druga',
'hidos',
'regat',
'unire',
'vinde',
'vampa',
'asalt',
'apela',
'omida',
'tutun',
'lotus',
'magie',
'judec',
'sotie',
'sacaz',
'enorm',
'zefir',
'autor',
'ursit',
'bruia',
'audit',
'rural',
'rapel',
'hiena',
'delta',
'tagma',
'bezea',
'stres',
'stiva',
'nebun',
'seara',
'luciu',
'fitil',
'bitum',
'bazar',
'razie',
'sodiu',
'nerod',
'aviar',
'fosil',
'musca',
'acord',
'lunca',
'cuier',
'cheli',
'tilda',
'labil',
'morun',
'capos',
'sabat',
'dinte',
'rabla',
'draga',
'aviza',
'copca',
'fapta',
'saten',
'uzura',
'guler',
'lupta',
'gelos',
'totem',
'aroma',
'briza',
'bizon',
'viata',
'masaj',
'fibra',
'tesla',
'putin',
'alura',
'ghioc',
'deces',
'vifor',
'galop',
'maica',
'optim',
'colaj',
'spusa',
'pista',
'culpa',
'clapa',
'gerar',
'ponor',
'culca',
'aprod',
'focar',
'alint',
'ponos',
'bujie',
'tunel',
'oftat',
'ibric',
'aflux',
'sagui',
'bazic',
'avant',
'gulas',
'gazon',
'polei',
'falit',
'magar',
'metru',
'dulce',
'aloca',
'natal',
'dihor',
'regal',
'tuica',
'minor',
'divan',
'piper',
'suvoi',
'mezin',
'uncie',
'veghe',
'atasa',
'sange',
'fiola',
'pasta',
'furou',
'copie',
'etate',
'valeu',
'axare',
'antic',
'spirt',
'maior',
'brosa',
'erect',
'cadra',
'zahar',
'grupa',
'capat',
'spera',
'balan',
'inapt',
'ranga',
'secta',
'neted',
'zvoni',
'actor',
'toxic',
'haina',
'nativ',
'debut',
'gauri',
'carou',
'coala',
'bonda',
'umila',
'ciuma',
'tango',
'maret',
'aeraj',
'bolid',
'buboi',
'flota',
'cafti',
'calau',
'boaba',
'coase',
'halba',
'suita',
'drept',
'cutit',
'conte',
'diliu',
'medie',
'depou',
'metis',
'asuma',
'magma',
'scuar',
'pacat',
'calca',
'febra',
'binar',
'gaina',
'bivol',
'litiu',
'rodaj',
'icnet',
'golas',
'lucid',
'salut',
'carda',
'barem',
'secui',
'acnee',
'topic',
'vames',
'judet',
'pazea',
'pateu',
'model',
'zvelt',
'liceu',
'minut',
'octan',
'cupru',
'telex',
'alibi',
'pizma',
'cablu',
'artar',
'zelos',
'maiou',
'volei',
'setos',
'orice',
'epava',
'sonda',
'doara',
'tubaj',
'aerat',
'psalm',
'biped',
'banui',
'sarpe',
'tenta',
'alege',
'amagi',
'baton',
'morsa',
'parau',
'arcus',
'miraj',
'taraf',
'serva',
'bizui',
'placa',
'banda',
'geaca',
'bluza',
'facil',
'piele',
'glosa',
'dilie',
'forja',
'proba',
'rotor',
'robie',
'talpa',
'ceata',
'senat',
'lamai',
'cuplu',
'nurca',
'tanji',
'hidra',
'banat',
'urale',
'cupon',
'foale',
'flama',
'imens',
'vodca',
'buzat',
'gazos',
'sfinx',
'neant',
'boare',
'opera',
'fobie',
'hotie',
'koala',
'truda',
'seama',
'cuina',
'titan',
'peste',
'ropot',
'clema',
'mugur',
'sacru',
'bursa',
'bazin',
'cidru',
'racla',
'curaj',
'bahic',
'iodat',
'urina',
'zonal',
'pauza',
'frica',
'pomet',
'marca',
'amiba',
'idiom',
'gulie',
'malac',
'ducat',
'druid',
'heliu',
'ravas',
'covor',
'unchi',
'pecet',
'ardei',
'infim',
'frana',
'poala',
'gazda',
'civil',
'spala',
'linte',
'coleg',
'misto',
'bruma',
'avion',
'afgan',
'hamac',
'banan',
'bemol',
'aurar',
'dingo',
'pivot',
'canal',
'folos',
'triaj',
'votum',
'aliat',
'iarba',
'lunar',
'caval',
'etern',
'tunet',
'alina',
'firav',
'palos',
'carne',
'senil',
'bufet',
'boboc',
'forta',
'fonic',
'agale',
'iarna',
'penal',
'buget',
'atriu',
'proza',
'niplu',
'orfan',
'fagot',
'lenta',
'peren',
'ciuta',
'sfera',
'jeleu',
'surzi',
'betiv',
'bunic',
'ideal',
'osana',
'satra',
'soare',
'razbi',
'tenis',
'stupi',
'vraja',
'crima',
'posac',
'arian',
'nipon',
'rabat',
'argou',
'clapc',
'vibra',
'muson',
'docil',
'macar',
'coasa',
'sonor',
'ricin',
'razes',
'fazan',
'cufar',
'papuc',
'sinod',
'morga',
'circa',
'filet',
'merit',
'mojic',
'aftos',
'modic',
'norod',
'mogul',
'venos',
'joaca',
'cobra',
'civic',
'necaz',
'leafa',
'aztec',
'splai',
'bacan',
'mahar',
'citat',
'udare',
'halca',
'velur',
'pasca',
'nociv',
'sapca',
'manea',
'curca',
'marar',
'dacic',
'fonie',
'valug',
'prund',
'xenon',
'scuza',
'fanta',
'grila',
'teaca',
'latin',
'catea',
'cocos',
'lipsa',
'hrana',
'vlaga',
'delir',
'fetus',
'pariu',
'modal',
'cursa',
'plumb',
'zenit',
'doliu',
'tonaj',
'pasiv',
'sumar',
'tihna',
'zagan',
'eunuc',
'optic',
'tipar',
'caini',
'valet',
'rubla',
'mango',
'sabie',
'hotar',
'macaz',
'lejer',
'agent',
'picaj',
'felin',
'sapte',
'pizza',
'sudic',
'apune',
'grota',
'miner',
'flanc',
'gerid',
'bufon',
'gluga',
'acont',
'cadea',
'inert',
'pepit',
'salam',
'retur',
'mamar',
'crema',
'salon',
'fixaj',
'etnie',
'abces',
'coana',
'fiica',
'barja',
'lauza',
'tract',
'dator',
'mobil',
'farsa',
'albii',
'craci',
'armie',
'tatic',
'biban',
'ulita',
'albus',
'perna',
'plita',
'onest',
'evada',
'ecran',
'cleri',
'bocet',
'scump',
'munca',
'larma',
'pegas',
'nivel',
'aduce',
'homar',
'gales',
'tulai',
'careu',
'caisa',
'javra',
'polar',
'manta',
'pupic',
'axila',
'pasla',
'navod',
'epica',
'regim',
'tarif',
'bucla',
'fagas',
'verva',
'rasad',
'pruna',
'supus',
'colac',
'morar',
'pavaj',
'talaz',
'balti',
'gasca',
'iures',
'vasal',
'garda',
'taios',
'vasla',
'sigla',
'haios',
'salau',
'epoca',
'sacou',
'hazna',
'flaut',
'metil',
'comic',
'adult',
'acasa',
'topor',
'baril',
'urare',
'funda',
'cazma',
'argon',
'pepsi',
'tigai',
'sifon',
'front',
'tipat',
'dauna',
'adaos',
'birui',
'boier',
'modul',
'bagaj',
'caiac',
'cifra',
'ciclu',
'cizma',
'pilot',
'dulau',
'catun',
'risca',
'dulie',
'eroic',
'scrib',
'senin',
'penar',
'halva',
'motel',
'impar',
'bloca',
'teava',
'capot',
'ploua',
'bunel',
'bubos',
'organ',
'kebab',
'debit',
'drops',
'sugar',
'latex',
'zeita',
'gater',
'macel',
'ciobi',
'vadra',
'doica',
'flirt',
'tiran',
'dicta',
'dineu',
'gluma',
'suflu',
]
