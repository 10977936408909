import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Cum se joacă" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Ghicește cuvântul din 6 încercări. După fiecare încercare, culoarea literelor se schimbă și îți arată cât de aproape ai fost de a ghici cuvântul:      
</p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="C" status="correct" />
        <Cell value="O" />
        <Cell value="P" />
        <Cell value="I" />
        <Cell value="L" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Litera C există în cuvânt și e în locul corect.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="G" />
        <Cell value="U" />
        <Cell value="L" status="present" />
        <Cell value="E" />
        <Cell value="R" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Litera L e în cuvânt, dar nu e în locul corect.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="H" />
        <Cell value="A" />
        <Cell value="I" />
        <Cell value="N" status="absent" />
        <Cell value="E" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Litera N nu e deloc în cuvânt.
      </p>


      <p className="text-sm text-gray-500 dark:text-gray-300"><br /><br />
        Adaptare de <a href="https://sirb.net/tag/wordle-ro/" className="underline font-bold">Ovidiu Sîrb</a> a celebrului joc <a href="https://www.powerlanguage.co.uk/wordle/" className="underline font-bold">Wordle</a> {'('}
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="underline font-bold"
        >
          codul sursă
        </a>{').'}
      </p>
    </BaseModal>
  )
}
