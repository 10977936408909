import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Donează!" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
Wordle-RO va fi <strong>întotdeauna gratuit</strong>, dar dacă vrei, poți să-mi faci cinste cu o cafea ☕ {' '}        <a
          href="https://ko-fi.com/ovisirb"
          className="underline font-bold"
        >
          aici
        </a>{'.'}<br /><br />
      </p>
    </BaseModal>
  )
}
